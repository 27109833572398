import "./styles.css";
import * as THREE from "three";
import * as lilgui from "lil-gui";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
// console.log(THREE);

// Debug
// const gui = new lilgui.GUI();

const canvas = document.querySelector(".webgl");
const scene = new THREE.Scene();

const resetBtn = document.querySelector(".resetBtn");
resetBtn.addEventListener("click", resetScreen);

const elementCount = document.querySelector(".elementCount");

let smallFenceBtn = document.getElementById("smallFenceBtn");
let largeFenceBtn = document.getElementById("largeFenceBtn");
let leftBevelFenceBtn = document.getElementById("leftBevelFenceBtn");
let rightBevelFenceBtn = document.getElementById("rightBevelFenceBtn");
let colorSelector = document.getElementById("colorSelector");
let displaySmallFenceCount = document.querySelector(".displaySmallFenceCount");
let displayLargeFenceCount = document.querySelector(".displayLargeFenceCount");
let displayLeftBevelFenceCount = document.querySelector(
  ".displayLeftBevelFenceCount"
);
let displayRightBevelFenceCount = document.querySelector(
  ".displayRightBevelFenceCount"
);

let fencesNames = [];

// let removeBtn = document.querySelector("#removeBtn");
// removeBtn.addEventListener("click", (e) => {
//   let lastNameInArray = fencesNames[fencesNames.length - 1];
//   e.preventDefault();

//   // console.log(lastNameInArray);
//   removeFromScene(lastNameInArray);
// });

let glanzGlasColor = "rgb(100,100,100)";
let milchGlasColor = "rgb(255,255,255)";
let grauGlasColor = "rgb(0,0,0)";

let glanzglasLabel = document.querySelector("#glanzglasLabel");
let milchglasLabel = document.querySelector("#milchglasLabel");
let grauglasLabel = document.querySelector("#grauglasLabel");
glanzglasLabel.style.backgroundColor = glanzGlasColor;
milchglasLabel.style.backgroundColor = milchGlasColor;
grauglasLabel.style.backgroundColor = grauGlasColor;

let fenceAmountText = "Anzahl: ";

let maxFences = 4;
elementCount.addEventListener("change", (_event) => {
  maxFences = _event.target.value;
});

let fenceDepth = 0.1;
const fenceHeight = 1.8;
const smallFenceWidth = 1.0;
const largeFenceWidth = 1.8;

let keyboardMoveSpeed = 10;

let startPositionX = 1.5;
let lastPositionX = 0.0;

let positionZ = -2;

let clickCount = 0;
let smallFenceCount = 0;
let largeFenceCount = 0;
let leftBevelFenceCount = 0;
let rightBevelFenceCount = 0;

let isSmallFence = false;

let smallFence;
let largeFence;
let leftBevelFence;
let rightBevelFence;

let LastGeometryName = "";

let color = "rgb(255, 0, 255)";
let borderColor = "rgb(0, 0, 0)";

let radioButtons = document.forms["colors"].elements["glasskind"];

const sizes = {
  width: window.innerWidth * 0.9,
  height: window.innerHeight,
};

// resize window
window.addEventListener("resize", () => {
  sizes.width = window.innerWidth;
  sizes.height = window.innerHeight;
});

// camera
const camera = new THREE.PerspectiveCamera(
  75,
  sizes.width / sizes.height,
  0.1,
  100
);
camera.position.z = -5;
scene.add(camera);

// update camera
camera.aspect = sizes.width / sizes.height;
camera.updateProjectionMatrix();
// controls
const controls = new OrbitControls(camera, canvas);
controls.enabled = true;
controls.enableDamping = true;

// renderer
const renderer = new THREE.WebGLRenderer({
  alpha: true,
  canvas: canvas,
});
renderer.setSize(sizes.width, sizes.height);
// update the renderer
renderer.setSize(sizes.width, sizes.height);
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

for (var i = 0, max = radioButtons.length; i < max; i++) {
  radioButtons[i].onclick = function () {
    switch (this.value) {
      case "glanzglas":
        color = glanzGlasColor;
        break;
      case "milchglas":
        color = milchGlasColor;
        break;
      case "grauglas":
        color = grauGlasColor;
        break;
    }
  };
}

const group = new THREE.Group();

const hemiLight = new THREE.HemisphereLight(0xffffff, 0x444444, 0.5);
// gui.add(hemiLight, "visible").name("Hemisphere Light");
// gui
//   .add(hemiLight, "intensity")
//   .min(0)
//   .max(1)
//   .step(0.001)
//   .name("Hemisphere Light Intensity");
hemiLight.position.set(0, 20, 0);
scene.add(hemiLight);

const dirLight = new THREE.DirectionalLight(0xffffff, 0.2);
// gui.add(dirLight, "visible").name("Directional Light");
// gui.add(hemiLight, "intensity").name("Hemisphere Light Intensity");
dirLight.position.set(-3, 10, -10);
dirLight.castShadow = true;
dirLight.shadow.camera.top = 15;
dirLight.shadow.camera.bottom = -1;
dirLight.shadow.camera.left = -2;
dirLight.shadow.camera.right = 2;
dirLight.shadow.camera.near = 0.1;
dirLight.shadow.camera.far = 40;
scene.add(dirLight);

// load scene from json
// scene = loadSceneFromJson(scene);

scene.add(group);

smallFenceBtn.addEventListener("click", (e) => {
  e.preventDefault();
  if (clickCount < maxFences) {
    addFence("small");
  }
});
largeFenceBtn.addEventListener("click", (e) => {
  e.preventDefault();
  if (clickCount < maxFences) {
    addFence("large");
  }
});
leftBevelFenceBtn.addEventListener("click", (e) => {
  e.preventDefault();
  if (clickCount < maxFences) {
    addFence("leftBevel");
  }
});
rightBevelFenceBtn.addEventListener("click", (e) => {
  e.preventDefault();
  if (clickCount < maxFences) {
    addFence("rightBevel");
  }
});

// loads the scene export from threejs editor for expample
function loadSceneFromJson(scene) {
  const loader = new THREE.ObjectLoader();
  loader.load(
    "assets/json/scene.json",
    function (obj) {
      scene.add(obj);
    },
    function (xhr) {
      console.log((xhr.loaded / xhr.total) * 100 + "% loaded");
    },
    function (error) {
      console.log("Fehler: " + error);
    }
  );

  return scene;
}

const textureLoader = new THREE.TextureLoader();
const brickTexture = new THREE.TextureLoader().load("textures/brick/brick.jpg");
const brick = new THREE.Mesh(
  new THREE.BoxGeometry(6, 4, 0.1),
  new THREE.MeshBasicMaterial({ map: brickTexture })
);

scene.add(brick);

function addFence(fenceType) {
  switch (fenceType) {
    case "small":
      if (clickCount === 0) {
        smallFence = generateBox(
          smallFenceWidth,
          fenceHeight,
          fenceDepth,
          color
        );
        smallFence.name = `smallFence-${clickCount}`;
        smallFence.position.x = startPositionX;
        // smallFence.position.y = smallFence.geometry.parameters.height / 2;
        smallFence.position.y = -fenceHeight / 2;
        smallFence.position.z = positionZ;
        lastPositionX = startPositionX;
        isSmallFence = true;

        fencesNames.push(smallFence.name);

        // ground.add(smallFence);
      } else {
        smallFence = generateBox(
          smallFenceWidth,
          fenceHeight,
          fenceDepth,
          color
        );
        smallFence.name = `smallFence-${clickCount}`;
        smallFence.position.x = isSmallFence
          ? lastPositionX - smallFenceWidth
          : lastPositionX - (smallFenceWidth + 0.4);
        smallFence.position.y = -fenceHeight / 2;
        smallFence.position.z = positionZ;
        lastPositionX = smallFence.position.x;
        isSmallFence = true;

        fencesNames.push(smallFence.name);
      }
      clickCount++;
      smallFenceCount++;
      displaySmallFenceCount.innerHTML = fenceAmountText + smallFenceCount;
      // smallFence.rotation.set(0, 0, 0);
      group.add(smallFence);

      // group.add(smallFence);
      break;
    case "large":
      if (clickCount === 0) {
        largeFence = generateBox(
          largeFenceWidth,
          fenceHeight,
          fenceDepth,
          color
        );
        largeFence.name = `largeFence-${clickCount}`;
        largeFence.position.x = startPositionX;
        largeFence.position.y = -fenceHeight / 2;
        largeFence.position.z = positionZ;
        lastPositionX = startPositionX;
        isSmallFence = false;

        fencesNames.push(largeFence.name);
      } else {
        largeFence = generateBox(
          largeFenceWidth,
          fenceHeight,
          fenceDepth,
          color
        );
        largeFence.name = `largeFence-${clickCount}`;
        largeFence.position.x = isSmallFence
          ? lastPositionX - (largeFenceWidth - 0.4)
          : lastPositionX - largeFenceWidth;
        largeFence.position.y = -fenceHeight / 2;
        largeFence.position.z = positionZ;
        lastPositionX = largeFence.position.x;
        isSmallFence = false;

        fencesNames.push(largeFence.name);
      }
      clickCount++;
      largeFenceCount++;
      displayLargeFenceCount.innerHTML = fenceAmountText + largeFenceCount;
      group.add(largeFence);
      // group.add(largeFence);
      break;
    case "leftBevel":
      if (clickCount === 0) {
        leftBevelFence = generateBox(
          smallFenceWidth,
          fenceHeight,
          fenceDepth,
          color
        );
        leftBevelFence.name = `leftBevelFence-${clickCount}`;
        leftBevelFence.position.x = startPositionX;
        // leftBevelFence.position.y = leftBevelFence.geometry.parameters.height / 2;
        leftBevelFence.position.y = -fenceHeight / 2;
        leftBevelFence.position.z = positionZ;
        lastPositionX = startPositionX;
        isSmallFence = true;

        fencesNames.push(leftBevelFence.name);

        // ground.add(leftBevelFence);
      } else {
        leftBevelFence = generateBox(
          smallFenceWidth,
          fenceHeight,
          fenceDepth,
          color
        );
        leftBevelFence.name = `leftBevelFence-${clickCount}`;
        leftBevelFence.position.x = isSmallFence
          ? lastPositionX - smallFenceWidth
          : lastPositionX - (smallFenceWidth + 0.4);
        leftBevelFence.position.y = -fenceHeight / 2;
        leftBevelFence.position.z = positionZ;
        lastPositionX = leftBevelFence.position.x;
        isSmallFence = true;

        fencesNames.push(leftBevelFence.name);
      }
      clickCount++;
      leftBevelFenceCount++;
      displayLeftBevelFenceCount.innerHTML =
        fenceAmountText + leftBevelFenceCount;
      // leftBevelFence.rotation.set(0, 0, 0);
      group.add(leftBevelFence);

      // group.add(leftBevelFence);
      break;
    case "rightBevel":
      if (clickCount === 0) {
        rightBevelFence = generateBox(
          smallFenceWidth,
          fenceHeight,
          fenceDepth,
          color
        );
        rightBevelFence.name = `rightBevelFence-${clickCount}`;
        rightBevelFence.position.x = startPositionX;
        // rightBevelFence.position.y = rightBevelFence.geometry.parameters.height / 2;
        rightBevelFence.position.y = -fenceHeight / 2;
        rightBevelFence.position.z = positionZ;
        lastPositionX = startPositionX;
        isSmallFence = true;

        fencesNames.push(rightBevelFence.name);

        // ground.add(rightBevelFence);
      } else {
        rightBevelFence = generateBox(
          smallFenceWidth,
          fenceHeight,
          fenceDepth,
          color
        );
        rightBevelFence.name = `rightBevelFence-${clickCount}`;
        rightBevelFence.position.x = isSmallFence
          ? lastPositionX - smallFenceWidth
          : lastPositionX - (smallFenceWidth + 0.4);
        rightBevelFence.position.y = -fenceHeight / 2;
        rightBevelFence.position.z = positionZ;
        lastPositionX = rightBevelFence.position.x;
        isSmallFence = true;

        fencesNames.push(rightBevelFence.name);
      }
      clickCount++;
      rightBevelFenceCount++;
      displayRightBevelFenceCount.innerHTML =
        fenceAmountText + rightBevelFenceCount;
      // rightBevelFence.rotation.set(0, 0, 0);
      group.add(rightBevelFence);

      // group.add(rightBevelFence);
      break;
  }
}

function generateBox(w, h, d) {
  const fence = new THREE.Mesh(
    new THREE.BoxGeometry(w, h, d),
    new THREE.MeshPhongMaterial({
      color: color,
      opacity: 0.5,
      transparent: true,
    })
  );
  // shadow sender
  fence.castShadow = true;
  return fence;
}

const clock = new THREE.Clock();

const tick = () => {
  const elapsedTime = clock.getElapsedTime();

  // Update controls
  controls.update();

  // Render
  renderer.render(scene, camera);

  // Call tick again on the next frame
  window.requestAnimationFrame(tick);
};

function resetScreen() {
  location.href = "/";
}

function addToCart() {
  console.log("add to cart");
}

tick();
